import './Timer.scss';
import React, {Component} from 'react';

class Timer extends Component{
    constructor(props){
        super(props);
        this.state ={
            timerStarted: false,
            timerStoped:true,
            hours:0,
           
            minutes:0,
             seconds:0,
             captures:[]
            
        }

    }
    handelTimerStart(e){
        e.preventDefault();
        if(this.state.timerStoped){
             this.timer=  setInterval(()=>{
         
                this.setState({timerStarted: true, timerStoped:false});
                if(this.state.timerStarted){
                    if(this.state.seconds >= 60){
                        this.setState((prevState) => ({minutes:prevState.minutes + 1, seconds:0 }))

                    }
                    if(this.state.minutes >= 60){
                        this.setState((prevState) => ({hours:prevState.hours + 1 , minutes:0 ,  seconds:0 }))

                    }
                    this.setState((prevState) => ({seconds:prevState.seconds + 1 }))
                }
            

        }, 1000);
        
      }

    }

    handelTimerStop(e){
        e.preventDefault();
        this.setState({timerStarted:false,timerStoped:true});
        clearInterval(this.timer);

    }
    render(){
        return(
            <>
            <div className="container">
                <h2 className="text-content">React</h2>
                <div className="timer-container">
                    <div className="current-timer">
                        {this.state.hours +":"+this.state.minutes +":"+this.state.seconds}
                    </div>
                    <div className="timer-controls">
                        <button className="btn btn-success" onClick={this.handelTimerStart.bind(this)}>Start</button>
                        <button className="btn btn-alert" onClick={this.handelTimerStop.bind(this)}>Stop</button>

                    </div>

                </div>

            </div>       

        </>
        )
    }
}
export default Timer;