import React, {Component} from 'react';
import {Link} from 'react-router-dom';

// Plugin
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";

// Components
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';

// Properties
var properties = require('../../properties.json');

class Home extends Component {
    state = {
        companyID: properties.company_id,
        branchID: properties.branch_id,

        // Get Current User
        currentUser: localStorage.getItem('currentUser') ? JSON.parse(localStorage.getItem('currentUser')) : "",

        // Free Lessons
        freeLessons: []
    }

    componentDidMount() {
        document.title = properties.site_name;

        // Get Free Lessons From API
        fetch(properties.api_path + "/lesson/lessonList", {
            method: "POST",
            headers: {
                'Authorization': 'Bearer ' + this.state.currentUser.token,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                branchId: this.state.branchID,
                companyId: this.state.companyID,
                lessonType: 1,
                schoolId: (this.state.currentUser) ? this.state.currentUser.studentData.schoolStagesId : null
            })
        })
        .then(res => {return res.json()})
        .then(fetchFreeLessons => {
            this.setState({freeLessons: fetchFreeLessons.slice(0, 3)});
        })
    }

    render() {
        
        // Import CSS
        require('./Home.scss');

        let getAllFreeLessons = this.state.freeLessons.map(freeLesson => 
            <div className="col-md-4">
                <div className="video">
                    <Link to={`/free-videos/${freeLesson.id}`}>
                        <div className="thumb">
                            <img src={`${properties.uploads_path}/${freeLesson.thumb}`} alt={freeLesson.lessonName}/>
                            <div className="hover-layer">
                                <i className="fas fa-play-circle"></i>
                            </div>
                        </div>
                        <div className="video-title">
                            <h4>{freeLesson.lessonName}</h4>
                        </div>
                    </Link>
                </div>
            </div>
        );

        const options = {
            showThumbs: false,
            showStatus: false,
            autoPlay: true,
            stopOnHover: false,
            infiniteLoop: true,
            interval: 7000
        };

        let teacherName = properties.teacher.name;
        let teacherImage = require(`../../assets/img/${properties.teacher.img}`).default;
        let teacherDescription = properties.teacher.description;

        let getSlider = properties.slider.map((slide, i) =>
            <div className="slide" key={i} style={{backgroundImage: `url(${require(`../../assets/img/${slide.img}`).default})`}}>
                <div className="overlay">
                    <div className="container">
                        <h3><b>{slide.text}</b></h3>
                    </div>
                </div>
            </div>
        );

        return(
            <>
                <Header />

                <div className="home-slider">
                    <Carousel {...options}>
                        {getSlider}
                    </Carousel>
                </div>

                {(this.state.currentUser === "") ? (
                    <div className="user-buttons">
                        <div className="row mx-0 no-gutters">
                            <div className="col-6">
                                <Link className="u-btn sign-up" to="sign-up"><i className="fas fa-user-plus"></i> أنشئ حساب جديد</Link>
                            </div>
                            <div className="col-6">
                                <Link className="u-btn sign-in" to="sign-in"><i className="fas fa-sign-in-alt"></i> سجل دخولك الأن</Link>
                            </div>
                        </div>
                    </div>
                ) : ""}

                <div className="about-teacher">
                    <div className="container">
                        <div className="section-title">
                            <h3>عن الأستاذ {teacherName}</h3>
                            <div className="hr"></div>
                        </div>

                        <div className="teacher-bio">
                            <div className="row align-items-center">
                                <div className="col-md-8">
                                        <div className="text"> - الأستاذ <span className="teacher-name"><b>{teacherName} </b></span></div> 

                                    <p>{teacherDescription}</p>
                                </div>

                                <div className="col-md-4">
                                    <img src={teacherImage} alt={teacherName} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="free-videos">
                    <div className="container">
                        <div className="section-title">
                            <h3>الڤيديوهات المجانية</h3>
                            <div className="hr"></div>
                        </div>

                        <div className="row">
                            {(this.state.freeLessons.length) ? (
                                getAllFreeLessons
                            ): <div className="no-items">
                                    <p>لا يوجد دروس مجانية</p>
                                </div> }
                        </div>
                        <Link to="/free-videos/" className="all-videos">كل الڤيديوهات</Link>
                    </div>
                </div>
           
                <Footer />
            </>
        );
    }
}

export default Home;